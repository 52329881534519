.form {
  width: 500px;
  background: #ffffff;
  border-radius: 10px;
  outline: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 37px 56px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (max-width: 763px) {
    width: 473px;
  }
  @media (max-width: 500px) {
    width: 350px;
    padding: 37px 19px 20px;
  }
}
.close {
  color: #757575;
  outline: none;
  border: none;
  background-color: inherit;
  position: absolute;
  right: 34px;
  top: 31px;
  cursor: pointer;
}

.logo {
  margin-bottom: 45px;

  @media (max-width: 500px) {
    margin-bottom: 25px;
  }
}

.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: #757575;
  margin-bottom: 5px;
  @media (max-width: 763px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
  @media (max-width: 500px) {
    margin-bottom: 6px;
  }
}

.sendBtn {
  display: block;
  width: 246px;
  height: 53px;
  border: 2px solid #e64147 !important;
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #000 !important;
  background-color: inherit;
  border-radius: 8px !important;
  cursor: pointer;
  margin-top: 20px !important;
  @media (max-width: 500px) {
    width: 213px;
    margin-top: 6px !important;
    font-size: 12px !important;
  }
}

.success {
  width: 500px;
  min-height: 550px;
  background: #ffffff;
  border-radius: 10px;
  outline: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 41px 56px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (max-width: 763px) {
    width: 473px;
  }
  @media (max-width: 500px) {
    width: 350px;
    min-height: 550px;
    padding: 37px 19px 0px;
    height: 400px;
  }
}

.supportBg {
  margin-bottom: 30px;
}

.or {
  position: absolute;
  left: 10px;
  top: 93px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
}
