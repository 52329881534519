@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500;600;700;900&family=Montserrat:wght@400;500;600;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

*,
html {
  font-family: 'Montserrat'!important;
}

strong, b {
  font-weight: 600 !important;
}

#nprogress .bar {
  background: #E52428;
  z-index: 1202;
}

#nprogress .peg {
  display: none;
}

#nprogress .spinner-icon {
  border-top-color: #E52428;
  border-left-color:#E52428;
}

.swiper-button-disabled svg path {
   fill: #9E9E9E;
}

.swiper-button-disabled svg circle {
  stroke: #9E9E9E;
}

.swiper-slide {
  display: flex;
  max-height: auto !important;
}
